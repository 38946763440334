/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-08 10:55:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-11 14:15:31
 * @FilePath: /mediatom-web/src/apiForManage/dashboard.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const dashboardApi = {
  // 消耗汇总信息
  summaryData: '/dashboard/summary_data',
  // 今日数据
  todayData: '/dashboard/today_data',
  // 首页布局
  settingLayout: '/dashboard/layoutSetting',
  getTrafficPlatData: '/dashboard/traffic_plat_data',
  logPage: '/user/getLogPage'
}

export function summaryData (data) {
  return request({
    url: dashboardApi.summaryData,
    method: 'post',
    data: data
  })
}

export function todayData (data) {
  return request({
    url: dashboardApi.todayData,
    method: 'post',
    data: data
  })
}

export function settingLayout (data) {
  return request({
    url: dashboardApi.settingLayout,
    method: 'post',
    data: data
  })
}

export function getTrafficPlatData (data) {
  return request({
    url: dashboardApi.getTrafficPlatData,
    method: 'post',
    data: data
  })
}

export function logPage (data) {
  return request({
    url: dashboardApi.logPage,
    method: 'post',
    data: data
  })
}
